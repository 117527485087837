import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import image from './Images/landing_sunshineroom.png';
import background from './Images/sunshine_pattern.png';
import { FaChevronRight } from "react-icons/fa6";

const BackgroundImage = styled.div`
    background-image: url(${image}); 
    background-repeat:no-repeat;
    width:100%;
    height:100%;
    position:fixed;
    & .header-container:{
        text-align:left;
    }
`;

const HomeTitle = styled.h1`
   
    color: #025159;
    font-family: "Gowun Batang";
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px; 
    margin-top:250px;
    text-align:left;
    padding-left:50px;
    
`;
const SubTitle = styled.h3`
   
    color: #025159;
    font-family: "Gowun Batang";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px;
    text-align:left;
    padding-left:50px;
    
`;
const SubTitle2 = styled.h3`
   
    color: #025159;
    font-family: "Gowun Batang";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 80px; 
    text-align:left;
    padding-left:50px;
    position: fixed;
    bottom: 0;
   

`;
const BackgroundImg = styled.img`
  background-image: url(${background});
  background-repeat:repeat-x
  width:100%

   max-height:400px;
   margin-top:300px;
   position:fixed;
   z-index:-1
`;

const iconDiv = {width:"100%", height:"75px", display:"flex", alignItems:"center", marginLeft:"270px"}
const icon = {color:"#025159", width:"43px", height:"34px", marginRight:"20px"}


export default function HomePage() {

    return (
      
     
        <BackgroundImage>
        <div className="header-component">
        <HomeTitle>Sunshine Cove</HomeTitle>
        <SubTitle>An AR space to
        <div>revisit special memories.</div>
        </SubTitle>
  
        <div>
        <Link style={{textDecoration:'none', color: 'black'}} 
                        to={`/sunshine`}> 
            <div style={iconDiv}>
            <p style={{color:"#025159", fontSize:"24px", fontWeight:"700"}}>Next </p>
           <FaChevronRight style={icon} />
            </div>

     
        </Link>
        </div>
        <SubTitle2>Moments to keep forever.</SubTitle2>
        </div>     
        </BackgroundImage>

    );
}

