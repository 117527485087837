import './App.css';
import React, {useEffect, useState} from "react";
import { Routes, Route} from "react-router-dom";
import Form from './Form';
import HomePage from './HomePage';
import Sunshine from './Sunshine';

function App() {
  const [userInfo, setUserInfo] = useState([])
  const [data, setData] = useState([])
  console.log(data)

  useEffect(()=>{
    const fetchData = async () => {
      // const defaultHeaders = {
      //   "Content-Type": "application/json",
      //   Accept: "application/json",
      // }

      const response = await fetch('https://cat-api.bripi.com/home')
      const data = await response.json()

      setData(data)
      

    }
    fetchData()
  }, [])

  console.log(userInfo)
  return (
    <div className="App" style={{display:"flex", justifyContent:"center", alignContent:"center"}}>
      

        <Routes>
          <Route path="/form" element={<Form userInfo={userInfo} setData={setData} data={data}/>} /> 
          <Route path="/" element={<HomePage userInfo={userInfo} />} /> 
          {/* <Route path="/sunshine" element={<Sunshine userInfo={userInfo} />} />  */}
          <Route path="/sunshine" element={<Sunshine data={data} setData={setData} />} /> 
        </Routes>

    </div>
  );
}



export default App;
