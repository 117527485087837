import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";


import { GiSpeaker } from "react-icons/gi";
import { FaVideo } from "react-icons/fa";
import { FaNoteSticky } from "react-icons/fa6";
import { FaImage } from "react-icons/fa6";
import { HiSpeakerWave } from "react-icons/hi2";


const Title = styled.h2`
  color: #025159;

  font-family: "Gowun Batang";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: r0px;
  text-align:left; 
`;

const FormContainer = styled.div`
   
    display:flex;
    flex-direction:column;
    align-content:center;
    align-items:center;
    justify-content:center;
    width:288px;


    
`;
const Label = styled.label`
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Gowun Batang";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 108.333% */
    letter-spacing: 0.46px;
    text-transform: uppercase; 
    background-color:rgba(242, 172, 87, 0.50);
    display:inline-block; 
    textAlign:center; 
    width:214px; 
    height:50px;
    color: var(--type_teal, #025159);
`;
const InputLabel = styled.input`
    background-color:rgba(242, 172, 87, 0.50);
    display:inline-block; 
    text-align:center;
    width:214px; 
    height:50px;

`;
const Button = styled.button`
border-radius: 20px;
background-color: var(--type_teal, #025159);
box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
width: 288px;
height: 57px;
color:white;
font-family: "Gowun Batang";
font-size: 24px;
font-style: normal;
font-weight: 700;




`;

export default function Form({ userInfo, data, setData }) {
  // const [data, setData] = useState("")
  // const [formData, setFormData] = useState([]);
  const [audio, setAudio] = useState({type:"audio", value:""});
  const [video, setVideo] = useState({type:"video", value:""});
  const [note, setNote] = useState({type:"note", value:""});
  const [image, setImage] = useState({type:"image", value:""});
  const navigate = useNavigate();


 
const convertBase64 = (file) => {
    // return new Promise((resolve, reject) => {
    //     const fileReader = new FileReader();
    //     fileReader.readAsBinaryString(file);
    //     fileReader.onload = () => {
    //         resolve(fileReader.result);
    //     };
    //     fileReader.onerror = (error) => {
    //         reject(error);
    //     };
    // });
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
    })
};

// reader.readAsBinaryString(file)

console.log(image)

const uploadImage = async (event) => {
  console.log(event)
    
    if (event.target.name === 'image'){
        const file = event.target.files[0];
        // const base64 = await fileToBase64(file.name, file.filepath);
        const base64 = await convertBase64(file);
        // console.log(base64, "testing")
        setImage({...image, value:base64})
    }
    // if (event.target.name === 'video'){
    //   const file = event.target.files[0];
    //   const base64 = await convertBase64(file);
    //   console.log(base64)
    //   console.log(event.target.files[0])
    // }
    
};

// input.addEventListener("change", (e) => {
//     uploadImage(e);
// });





  function handleSubmit(e) {
    
    e.preventDefault()
    console.log(image)
    console.log(video)
    const formData = [audio, video, image, note]
    console.log(formData)
    // console.log(formData)
    // const updatedData = [...data, formData]
  
    // console.log(updatedData)
  

    const postData = async () => {
      try {
        const response = await fetch(`https://cat-api.bripi.com/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          },
          body: JSON.stringify(formData)
        });
    
        if (response.ok) {
          const updated = await response.json();
          
          setData(updated)
          console.log("Success:", updated);
     
          // Handle success, if necessary
        } else {
          console.error("Server Error:", response.status, response.statusText);
          // Handle server error or other non-successful responses
        }
      } catch (error) {
        console.error("Client Error:", error);
        // Handle client-side error
      }

    };
    postData()
    navigate("/sunshine")
  }

  

  const iconDiv = {width:"100%", height:"75px", display:"flex", alignItems:"center"}
  const icon = {color:"#025159", width:"43px", height:"34px", marginRight:"20px"}


  return (
    <div style={{width:"360px", display:"flex", justifyContent:"center", alignItems:"center", alignContent:"center", flexDirection:"column"}}>
      <Title>Select the Memories you want to add</Title>
      <FormContainer>
      <form onSubmit={handleSubmit}>
        <div style={iconDiv}>
          <FaImage style={icon} />

        <Label htmlFor="image" style={{display:"inline-block", textAlign:"center", width:"214px", height:"50px", backgroundColor:"rgba(242, 172, 87, 0.50)", fontFamily:"Gowun Batang"}}>IMAGE</Label>
        <input
        
          type="file"
          id="image"
          name="image"
          accept="image/*"
    
          onChange={uploadImage}
        />
        </div>
        <div style={iconDiv}>
        <HiSpeakerWave style={icon} />
        <Label htmlFor="audio">AUDIO</Label>
        <input style={{width:"25%",backgroundColor:"rgba(242, 172, 87, 0.50)"}}
          type="file"
          id="audio"
          name="audio"
          accept="audio/*"
         
          onChange={uploadImage}
        />
        </div>
        <div style={iconDiv}>
        <FaVideo style={icon}/>
        <Label htmlFor="video" >VIDEO</Label>
        <input style={{width:"25%",backgroundColor:"rgba(242, 172, 87, 0.50)"}}
          type="file"
          id="video"
          name="video"
          value={video.value}
          // accept="video/*"
          onChange={(e)=>setVideo({...video, value:e.target.value})}
        />
        </div>
        <div style={iconDiv}>
        <FaNoteSticky style={icon}/>
        <InputLabel
        className="textinput"
        
        placeholder="NOTE"
        
        
          type="text"
          id="note"
          name="note"
          value={note.value}
          onChange={(e)=>setNote({...note, value: e.target.value})}
        />
      </div>
      {/* <Link style={{textDecoration:'none', color: 'black'}} 
                        to={`/sunshine`}>  */}
        
        <Button type="submit">Submit</Button>
 
       
      </form>
      </FormContainer>
    </div>
  );
}