import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import image1 from "./Images/graduation.png";
import image2 from "./Images/conference.png";
import image3 from "./Images/mit2.jpg";
import image4 from "./Images/hotair.jpg";
import image5 from "./Images/group.jpg"

export default function Sunshine({ userInfo, data }){



const StyledDiv = styled.div`
   &:hover{
    background-color:#025159;
    opacity:100%;
    color:white;
   };
   width: 100%;
   height: 60px;
   color:  #025159;
   background-color: #F2AC57;
   font-family: "Gowun Batang";
   z-index:2;
   display:flex;
   align-items:center;
   justify-content:center;
   position:fixed;
   bottom:0;
   font-size:25px
   
`;
const Button = styled.button`
   
    display: inline-block;
    outline: none;
    cursor: pointer;
    border-radius: 3px;
    font-size: 20px;
    font-weight: 700;
    font-family: "Gowun Batang";
    line-height: 16px;
    padding: 2px 16px;
    height: 38px;
    min-width: 200px;
    min-height: 38px;
    border: none;
    color: #fff;
    background-color: #025159;
    // transition: background-color .17s ease,color .17s ease;
    &:hover {
        background-color:#F2AC57;
        color:white;
        opacity:100%;
    }

   
`;
const Note = styled.h3`
//    &:hover{
//     background-color:#025159;
//     opacity:100%;
//     color:white;
//    };
    color: #025159;
    font-family: "Gowun Batang";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px;
   }
`;

const Add = styled.h3`

    color: #025159;
    font-family: "Gowun Batang";
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    &:hover{
        color:white;
    }
   
`;
// const userContainers = userInfo.map((info)=>{
const icon = {color:"#025159", width:"60px", height:"50px"}
    const userContainers = data.map((info)=>{
        if (info.type === "image"){
            if (info.value){
                return(
                    <div style={{width:"100%", display:"flex", alignContent:"center", justifyContent:"center"}}>
                    <div style={{width:"300px", height:"300px", display:"flex", alignItems:"center"}}>
                        <img style={{borderRadius:"25px",width:"100%", height:"100%", objectFit:"cover"}} src={info.value}>
                        </img>   
                    </div>
                </div>     
                )
            }
        }
        // if (info.type === "video"){
        //     return(
        //         <div style={{width:"100%",height:"150px", display:"flex", alignContent:"center", justifyContent:"center", paddingTop:"100px"}}>
        //             <div style={{borderRadius:"25px",width:"300px", height:"150px", display:"flex", alignItems:"center"}}>
        //                 <video style={{borderRadius:"25px", objectFit:"cover", height:"100%", width:"100%"}}controls>
        //                     <source src={info.value} type="video/mp4">
        //                 </source>
        //                 </video>
        //             </div>
        //         </div>
        //     )
        // }
        // if (info.type === "audio"){
        //     return(
        //         <div style={{borderRadius:"20px", maxWidth:"100%", height:"120px", display:"flex", alignItems:"center", alignContent:"center",justifyContent:"center", }}>
        //             <div style={{borderRadius:"25px",width:"300px", height:"150px", display:"flex", alignItems:"center",backgroundColor:"#F2AC57", opacity:"75%"}}>
        //         <audio style={{borderRadius:"25px", objectFit:"cover"}} controls>
        //         <source src={info.value} type="audio/ogg" />
        //       </audio>
        //       </div>
        //       </div>
                
        //     )
        // }
        if (info.type === "note"){
            return(
                <div style={{borderRadius:"20px", maxWidth:"100%", height:"300px", width:"300px", display:"flex", textAlign:"center", backgroundColor:"#F2AC57", opacity:"75%"}}>
                {/* <div style={{display:"flex", justifyContent:"center",borderRadius:"25px", width:"300px", height:"300px",backgroundColor:"#F2AC57", opacity:"75%", textAlign:"center"}}> */}
                    <Note>{info.value}</Note>
                {/* </div> */}
            </div>
        )  }
    })
    


    return(
        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"25px"}}>
            <div style={{margin:"auto", padding:"20px", width:"75%", textAlign:"left"}}> 
                 <h3 style={{color: "#025159",fontFamily:"Gowun Batang",fontSize:"36px",fontWeight:"700",lineHeight:"40px"}}>
                Elena's Sunshine Room</h3>
            </div>
            {/* <div style={{width:"250px",display:"flex", justifyContent:"center", alignItems:"center"}}>
                <button style={{boxShadow:"0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",font-size:"18px", textAlign:"center",height:"57px", width:"100%",borderRadius:"20px", background:"#025159", color:"white", fontFamily:"Gowun Batang", marginBottom:"50px"}}>ENTER YOUR ROOM</button>
            </div> */}
            <div style={{flex:"50%",paddingLeft:"25px", paddingRight:"25px",display:"flex", flexDirection:"column", flexWrap:"wrap", justifyContent:"space-between", gap:"100px", width:"288px" }}>
            <div>
            <Button>ENTER YOUR ROOM</Button>
            </div>
                {userContainers}
                <div style={{width:"100%", display:"flex", alignContent:"center", justifyContent:"center"}}>
                    <div style={{width:"300px", height:"300px", display:"flex", alignItems:"center"}}>
                        <img style={{borderRadius:"25px",width:"100%", height:"100%", objectFit:"cover"}} src={image1}>
                        </img>   
                    </div>
                </div>  
                <div style={{borderRadius:"20px", maxWidth:"100%", height:"300px", width:"300px", display:"flex", textAlign:"center", backgroundColor:"#F2AC57", opacity:"75%"}}>
                    {/* <div style={{display:"flex", justifyContent:"center",borderRadius:"25px", width:"300px", height:"300px",backgroundColor:"#F2AC57", opacity:"75%", textAlign:"center"}}> */}
                        <Note>Affirmation: I face challenges with courage and embrace growth opportunities.</Note>
                    {/* </div> */}
                </div>
                <div style={{width:"100%", display:"flex", alignContent:"center", justifyContent:"center"}}>
                    <div style={{width:"300px", height:"300px", display:"flex", alignItems:"center"}}>
                        <img style={{borderRadius:"25px",width:"100%", height:"100%", objectFit:"cover"}} src={image2}>
                        </img>   
                    </div>
                </div>  
                <div style={{width:"100%", display:"flex", alignContent:"center", justifyContent:"center"}}>
                    <div style={{width:"300px", height:"300px", display:"flex", alignItems:"center"}}>
                        <img style={{borderRadius:"25px",width:"100%", height:"100%", objectFit:"cover"}} src={image3}>
                        </img>   
                    </div>
                </div>  
                <div style={{borderRadius:"20px", maxWidth:"100%", height:"300px", width:"300px", display:"flex", textAlign:"center", backgroundColor:"#F2AC57", opacity:"75%"}}>
                    {/* <div style={{display:"flex", justifyContent:"center",borderRadius:"25px", width:"300px", height:"300px",backgroundColor:"#F2AC57", opacity:"75%", textAlign:"center"}}> */}
                        <Note>Affirmation: I choose to focus on the positive, embrace challenges as opportunities for growth, and live each day with gratitude and purpose.</Note>
                    {/* </div> */}
                </div>
                <div style={{width:"100%", display:"flex", alignContent:"center", justifyContent:"center"}}>
                    <div style={{width:"300px", height:"300px", display:"flex", alignItems:"center"}}>
                        <img style={{borderRadius:"25px",width:"100%", height:"100%", objectFit:"cover"}} src={image5}>
                        </img>   
                    </div>
                </div>  
                <div style={{borderRadius:"20px", maxWidth:"100%", height:"300px", width:"300px", display:"flex", textAlign:"center", backgroundColor:"#F2AC57", opacity:"75%"}}>
                    {/* <div style={{display:"flex", justifyContent:"center",borderRadius:"25px", width:"300px", height:"300px",backgroundColor:"#F2AC57", opacity:"75%", textAlign:"center"}}> */}
                        <Note>Affirmation: Every day, I take positive steps toward my goals and embrace the limitless potential within me.</Note>
                    {/* </div> */}
                </div>
                </div>
            <StyledDiv >
            <Link style={{textDecoration:'none', color: 'black'}} 
                        to={`/form`}> 
                 <Add style={{marginRight:"10px"}}>Add to Room</Add>
            </Link>
            <Link style={{textDecoration:'none',color:"#025159"}} 
                        to={`/form`}> 
            <FaPlus styles={icon}/>
            </Link>
            </StyledDiv >
        </div>
    )
}